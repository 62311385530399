a {
    color: inherit;
    text-decoration: none;
}

@import url('https://fonts.googleapis.com/css2?family=Bricolage+Grotesque:opsz,wght@10..48,300&family=Poppins:wght@100&display=swap');

/* Style my Header.js*/
.main {
    background-color: black;
    display: grid;
        grid-auto-columns: 1fr;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr;
        gap: 0em 0em;
        grid-template-areas:
            ". .";
}

.profilePic {
    border-radius: 50%;
    width: 100px;
    height: 100px;
    padding: 5px;
}
.profilePic:hover {
        animation: rotation .55s linear;
}
@keyframes rotation {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(359deg);
    }
}

#pin {
    height: 20px;
    width: 20px;
}

.location {
    color: white;
    font-size: 20px;
    font-weight: bold;
    font-family: 'Bricolage Grotesque', sans-serif;    
    font-family: 'Poppins', sans-serif;
    display: flex;
    align-items: center;
}

.pic{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
    gap: 20px;
    padding-left: 50px;
}

.buttonLayout{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 100%;        
    gap: 5px;
}

.buttons{
    color: white;
    background-color: transparent;
    width: 65px;
    padding: 5px;
    margin: 10px;
    border:none;
}
.buttons:hover{
    color: rgb(136, 135, 135);
    cursor: pointer;
}